import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import axios from "axios";
import store from "@/store"; //Used for global variables

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/projects",
        name: "projects",
        component: function () {
            return import(
                /* webpackChunkName: "projects" */ "../views/ProjectsView.vue"
            );
        },
    },
    {
        path: "/projectsWIP",
        name: "projectsWIP",
        component: function () {
            return import(
                /* webpackChunkName: "projectsWIP" */ "../views/ProjectsWIP.vue"
            );
        },
        // meta: {
        //     requiresAuth: true
        // }
    },
    {
        path: "/memberProjects",
        name: "memberProjects",
        component: function () {
            return import(
                /* webpackChunkName: "memberProjects" */ "../views/MemberProjects.vue"
            );
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/resume",
        name: "resume",
        // route level code-splitting
        // this generates a separate chunk (resume.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
            return import(
                /* webpackChunkName: "resume" */ "../views/ResumeView.vue"
            );
        },
    },
    {
        path: "/proBookmarklets",
        name: "proBookmarklets",
        component: function () {
            return import(
                /* webpackChunkName: "proBookmarklets" */ "../views/ProBookmarklets.vue"
            );
        },
    },
    {
        path: "/dateAndTime",
        name: "dateAndTime",
        component: function () {
            return import(
                /* webpackChunkName: "dateAndTime" */ "../views/DateAndTime.vue"
            );
        },
    },
    {
        path: "/colorGuessGame",
        name: "colorGuessGame",
        component: function () {
            return import(
                /* webpackChunkName: "colorGuessGame" */ "../views/ColorGuessGame.vue"
            );
        },
    },
    {
        path: "/gameMatching",
        name: "gameMatching",
        component: function () {
            return import(
                /* webpackChunkName: "gameMatching" */ "../views/GameMatching.vue"
            );
        },
    },
    {
        path: "/timerApp",
        name: "timerApp",
        component: function () {
            return import(
                /* webpackChunkName: "timerApp" */ "../views/TimerApp.vue"
            );
        },
    },
    {
        path: "/musicMaker",
        name: "musicMaker",
        component: function () {
            return import(
                /* webpackChunkName: "musicMaker" */ "../views/MusicMaker.vue"
            );
        },
    },
    {
        path: "/hunterCards",
        name: "hunterCards",
        component: function () {
            return import(
                /* webpackChunkName: "hunterCards" */ "../views/HunterCards.vue"
            );
        },
    },
    {
        path: "/codeReference",
        name: "codeReference",
        component: function () {
            return import(
                /* webpackChunkName: "codeReference" */ "../views/CodeReference.vue"
            );
        },
    },
    {
        path: "/dictionarySearch",
        name: "dictionarySearch",
        component: function () {
            return import(
                /* webpackChunkName: "dictionarySearch" */ "../views/DictionarySearch.vue"
            );
        },
    },
    {
        path: "/textExchange",
        name: "textExchange",
        component: function () {
            return import(
                /* webpackChunkName: "textExchange" */ "../views/TextExchange.vue"
            );
        },
    },
    {
        path: "/textToSpeech",
        name: "textToSpeech",
        component: function () {
            return import(
                /* webpackChunkName: "textToSpeech" */ "../views/TextToSpeech.vue"
            );
        },
    },
    {
        path: "/login",
        name: "LoginPage",
        component: function () {
            return import(
                /* webpackChunkName: "login" */ "../views/LoginPage.vue"
            );
        },
    },
    {
        path: "/colorTest",
        name: "ColorTest",
        component: function () {
            return import(
                /* webpackChunkName: "colorTest" */ "../views/ColorTest.vue"
            );
        },
    },
    {
        path: "/apiTest",
        name: "apiTest",
        component: function () {
            return import(
                /* webpackChunkName: "apiTest" */ "../views/APITest.vue"
            );
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/keyboardTester",
        name: "keyboardTester",
        component: function () {
            return import(
                /* webpackChunkName: "keyboardTester" */ "../views/KeyboardTester.vue"
            );
        },
        // meta: {
        //     requiresAuth: true
        // }
    },
    {
        path: "/imageCropper",
        name: "imageCropper",
        component: function () {
            return import(
                /* webpackChunkName: "imageCropper" */ "../views/ImageCropper.vue"
            );
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/foodFinder",
        name: "foodFinder",
        component: function () {
            return import(
                /* webpackChunkName: "foodFinder" */ "../views/FoodFinder.vue"
            );
        },
        // meta: {
        //     requiresAuth: true
        // }
    },
    {
        path: "/deepiAITextToImage",
        name: "deepiAITextToImage",
        component: function () {
            return import(
                /* webpackChunkName: "deepiAITextToImage" */ "../views/DeepiAITextToImage.vue"
            );
        },
        // meta: {
        //     requiresAuth: true
        // }
    },
    {
        path: "/flashCards",
        name: "flashCards",
        component: function () {
            return import(
                /* webpackChunkName: "flashCards" */ "../views/FlashCards.vue"
            );
        },
        // meta: {
        //     requiresAuth: true
        // }
    },
    {
        path: "/testPage",
        name: "testPage",
        component: function () {
            return import(
                /* webpackChunkName: "testPage" */ "../views/TestPage.vue"
            );
        },
        // meta: {
        //     requiresAuth: true
        // }
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach(async (to, from, next) => {
    //Need to store a global loading variable

    const API_URL = store.getters.getApiUrl; // Access the API URL from Vuex store

    // For this example, we're checking if a route requires authentication using meta tags
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const token = localStorage.getItem('chriscaudill-token');
    const username = localStorage.getItem('chriscaudill-username');

    const isLoggedIn = token && username;
    store.commit('SET_LOGGED_IN', isLoggedIn);

    if (requiresAuth && token && username) {
        try {
            const response = await axios.post(API_URL + "api/Auth/ConfirmToken", {
                username: username,
                token: token
            });

            if (response.status === 200) {
                next(); // continue to the route they were headed to
            } else {
                next('/login'); // redirect to login page or wherever you'd like them to go
            }
        } catch (error) {
            localStorage.removeItem('chriscaudill-token');
            localStorage.removeItem('chriscaudill-username');
            next('/login'); // redirect to login page
        }
    } else if (requiresAuth && (!token || !username)) {
        next('/login'); // redirect to login page
    } else {
        next(); // continue to the route they were headed to
    }
});

export default router;
